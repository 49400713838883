.panorama {
  height: 80px;
  margin-top: -79px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;

  > img {
    height: 100%;
  }
}

@media (min-width: 768px) {
  .panorama {
    margin-top: -80px;
  }
}
