#team {
  &Intro {
    > .row {
      flex-direction: column-reverse;
      .textCol {
        margin-bottom: 50px;
        h2 {
          margin: 0;
        }

        h4 {
          color: var(--red);
          margin-bottom: var(--default-margin);
        }
      }

      .imgCol {
        img {
          width: 100%;
          height: auto;
          max-height: 500px;
          object-fit: cover;
          border-radius: var(--default-border-radius);
        }
      }
    }
  }
  &Shorts {
    position: relative;
    background-image: url(../assets/graphics/TreeRings-02.svg),
      url(../assets/graphics/TreeRings-bottom-right.svg);
    background-repeat: no-repeat;
    background-position: top 0px right -150px, bottom 0px left -250px;
    background-size: 900px auto, 1000px auto;
    background-color: var(--dark-green);
    color: var(--white);
    padding: 100px 20px;

    > .container {
      position: relative;
      z-index: 1;
      > .row {
        flex-direction: column;
        gap: 30px;
      }
    }

    > .teamGradient {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(23, 51, 22, 0) -10%,
        var(--dark-green) 30%,
        var(--dark-green) 70%,
        rgba(23, 51, 22, 0) 110%
      );
    }
  }
}

@media (min-width: 768px) {
  #team {
    &Intro > .row {
      flex-direction: row;
      align-items: center;

      > .textCol {
        margin-bottom: 0px;

        > a {
          margin: 0px;
        }
      }
    }

    &Shorts > .container > .row {
      flex-direction: row;
      justify-content: space-around;

      > .teamMember {
        width: calc((100% - 30px) / 2);
      }
    }
  }
}

@media (min-width: 992px) {
  #teamShorts {
    background-image: url(../assets/graphics/TreeRings-02.svg);
    background-position: top 0px right -250px;
    background-size: 75vw auto;

    > .teamGradient {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, var(--dark-green) 60%, rgba(221, 84, 73, 0) 100%);
    }
    > .container > .row > .teamMember {
      width: calc((100% - 30px * 3) / 4);
    }
  }
}
