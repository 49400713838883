#cta {
  background-color: var(--dark-green);

  > .ctaGradient {
    background: var(--dark-green);
    background: linear-gradient(90deg, var(--dark-green) 32.81%, rgba(221, 84, 73, 0) 100%);
  }
}

.cta {
  color: var(--white);
  background-color: var(--red);
  background-image: url(../assets/graphics/TreeRings-02.svg);
  background-repeat: no-repeat;
  background-position: top 0 right -150px;
  background-size: 750px auto;
  padding: 50px 20px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;

  &.cta--config {
    color: var(--black);
    background: none;
    > .ctaContent {
      max-width: 900px;
    }
    > .ctaGradient {
      background: none;
    }
  }

  > .ctaContent {
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    > .btn {
      margin: 0;
    }

    h4 {
      margin-bottom: 5px;
    }
  }

  > .ctaGradient {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: var(--red);
    background: linear-gradient(90deg, var(--red) 32.81%, rgba(221, 84, 73, 0) 100%);
  }
}

@media (min-width: 768px) {
  .cta {
    padding: 50px;
    background-position: top 0 right 0px;

    > .ctaContent {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: 800px;

      h3 {
        margin-bottom: 0;
      }
    }
  }
}
