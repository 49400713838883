#configurator {
  white-space: break-spaces;

  .s {
    font-size: 12px;
  }

  .summary--footer {
    > p {
      margin: 0px;
    }
  }

  .minNightsWarning {
    // border: solid 1px var(--primary-green);
    padding: 6px;
    background-color: var(--warning);
    border-radius: var(--small-border-radius);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    > i {
      // color: var(--info);
      margin-right: 5px;
    }
  }

  .container.tight {
    p {
      text-align: left;
      margin-top: 0;
    }
  }
  .configuratorPacks {
    margin-bottom: 0px !important;

    > .titles {
      margin: 0 var(--default-margin);
    }
  }

  .configuratorStep {
    .input__cards {
      justify-content: center;
    }
    > h2 {
      margin-bottom: 0;
    }

    > h2,
    > h4,
    > p {
      text-align: center;
    }

    > h3 {
      margin-top: 75px;
    }

    > small {
      max-width: 600px;
      margin: auto;
      display: block;
      text-align: center;
    }

    > article {
      > .priceCard__header {
        > h3 {
          text-transform: capitalize;
        }
        > p {
          margin: 0;
          margin-top: 5px;
          // color: var(--gray);
        }
      }

      &.priceCard--summary {
        .summary__elt > .title span + span::before {
          content: ', ';
        }
      }

      &.inputsGroup {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: var(--default-margin);

        &.tight {
          max-width: 600px;
        }

        &.medTight {
          max-width: 800px;
        }
      }

      margin: 50px auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .pageBottom {
    margin: 0 auto;
    padding-bottom: 150px;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    > .btn {
      margin: 0;
    }

    > .progress {
      margin-top: 20px;
      width: 100%;
    }
  }
}

#priceNav {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--white);
  background-color: var(--dark-green);

  > .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    max-width: 1200px;
    align-items: center;
    padding: var(--default-padding);

    h5,
    p {
      margin: 0;
    }
  }
}

@media (min-width: 768px) {
  #priceNav {
    top: var(--navbar-height);
    bottom: auto;
  }
}

#step2 {
  .priceCard {
    margin: auto;
  }

  .inline-numbers {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;

    > .input {
      width: max-content;
    }
  }
}

#step3 {
  .checkboxes {
    > a,
    > small {
      width: 100%;
    }

    &.animations > .input__checkbox {
      width: calc(50% - 20px);
      min-width: 380px;
    }

    &.visits > .input__select {
      width: auto;
    }
  }

  .classNb {
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: center;
    gap: 5px;

    + small {
      width: 100%;
    }
  }
}

#step21 {
  .tax {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: var(--default-margin);

    > .input {
      width: fit-content;
    }
  }

  .priceCard {
    transform: scale(0.9);
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(0.95);
      transition: transform 0.3s ease-in-out;
    }

    &.checked {
      transform: scale(1);
      transition: transform 0.3s ease-in-out;
    }
  }
}

.notAvailable {
  color: var(--gray) !important;
  text-decoration: line-through;
}
