.carousel {
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-snap-points-x: repeat(calc(420px + var(--default-margin)));

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  // for the shadows
  padding: 15px 15px;

  &::-webkit-scrollbar {
    display: none;
  }

  > .packs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px;
    margin: auto;
    gap: var(--default-margin);
    flex-wrap: nowrap;
    width: fit-content;

    > .pack {
      width: calc(100vw - 2 * var(--default-margin));
    }
  }
}

@media (min-width: 768px) {
  .carousel {
    > .packs {
      > .pack {
        width: 100%;
        min-width: 420px;
        max-width: 500px;
      }
    }
  }
}
