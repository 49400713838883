#camp {
  &Presentation {
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 720px;
    align-items: center;

    > .desc {
      order: 2;
    }

    > .pictograms {
      order: 4;
    }

    > .ytVideo {
      order: 3;
    }

    > .sectionTitle {
      text-align: center;
      order: 0;
    }
  }

  &Building {
    display: flex;
    flex-direction: column;
    gap: 50px;

    &Specs {
      > h3 {
        text-align: center;
      }

      justify-content: center;
    }

    > .sectionTitle {
      margin: auto;
    }
  }

  &Specs {
    background-color: var(--light-green);
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .specs {
      width: 100%;
      // max-width: calc(2 * 400px + var(--default-margin));
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: var(--default-margin);
    }

    .spec {
      width: 100%;
      // max-width: 400px;
    }
  }

  &CG {
    .row {
      flex-direction: column;
      gap: 50px;
    }
  }

  &Gallery {
  }

  &Access > .dashedBox {
    border: 4px dashed var(--secondary-green);
    border-radius: var(--default-border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px var(--default-margin);
    margin-bottom: 50px;

    > .row {
      gap: var(--default-margin);
      flex-direction: column;
    }

    @media (min-width: 768px) {
      padding: 50px;
      flex-direction: column;

      > .row {
        flex-direction: row;
      }
    }
  }
}

.ytVideo {
  width: 100%;
  aspect-ratio: 16/9;
  border: none;
  border-radius: var(--default-border-radius);
}

@media (min-width: 768px) {
  #campPresentation > .pictograms {
    order: 1;
  }

  #campCG .row {
    flex-direction: row;
    gap: 0;
  }

  #campSpecs .spec {
    width: calc(100% / 2 - var(--default-margin));
  }
}
