.input__cards.activities {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.priceCard {
  filter: drop-shadow(var(--drop-shadow));
  gap: 0px !important;
  border-radius: calc(var(--default-border-radius) + 5px);
  border: 5px solid transparent;

  &--summary {
    .summary__elt {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &.checked {
    border: 5px solid var(--secondary-green);
    filter: drop-shadow(0px 0px 10px rgba(3, 141, 0, 0.5))
      drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  }

  &--summary {
    > .priceCard__price {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > .chf {
        text-decoration: underline;
      }
    }
  }

  > input {
    display: none;
    visibility: hidden;
  }

  &__header {
    padding: 15px 20px;
    text-align: center;
    border-top-left-radius: var(--default-border-radius);
    border-top-right-radius: var(--default-border-radius);
    background-color: var(--white);

    > h3,
    > h5 {
      margin: 0;
    }

    > h5 {
      margin-bottom: 5px;
    }

    > small {
      font-size: var(--fs-xs);
    }
  }

  &__price {
    background-color: var(--primary-green);
    color: var(--white);
    padding: 15px 20px;
    text-align: center;

    margin: 0;

    &--pack {
      text-decoration: underline;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    background-color: var(--white);

    border-bottom-left-radius: var(--default-border-radius);
    border-bottom-right-radius: var(--default-border-radius);

    > b {
      font-weight: 700;
    }

    > p {
      margin: 0;
      font-weight: 400;
    }

    > hr {
      height: 1px;
      max-width: 100%;
    }

    > small {
      font-style: italic;
      font-size: var(--fs-xs);
    }
  }
}

.carouselHolder {
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-snap-points-x: repeat(420px);
  padding: 0 15px;

  // Hides Scroll Bar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.input__cards.activities {
  flex-wrap: nowrap !important;
  margin-top: 0px;
  gap: 0px;

  .priceCard {
    width: 420px;
    height: fit-content;

    transform: scale(0.9);
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(0.95);
      transition: transform 0.3s ease-in-out;
    }

    &.checked {
      transform: scale(1);
      transition: transform 0.3s ease-in-out;
    }
  }
}
