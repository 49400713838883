.hero {
  background-image: url('../assets/img/work-in-progress-bg.jpg');
  background-position: center;
  background-size: cover;
  background-color: var(--primary-green);
  backdrop-filter: saturate(0);

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--white);
    text-shadow: var(--drop-shadow);
    background: rgba(70, 127, 43, 0.6);
    padding: 0 var(--default-margin);
  }

  &--full {
    height: 80vh;
    min-height: fit-content;
  }

  &--demi {
    height: 55vh;
  }

  h1 {
    margin: 0;
    text-align: center;
  }

  h4 {
    color: var(--light-green);
  }
}

@media (min-width: 768px) {
}
