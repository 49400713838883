#campBuildingFloors {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > h3 {
    width: 100%;
  }

  > .colRight {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 20px;
    justify-content: flex-start;

    .floorDescription > span {
      display: block;
      margin-bottom: 15px;
    }
  }

  > .colLeft {
    display: flex;
    flex-direction: column;
    gap: 20px;

    > img {
      width: 100%;
      border-radius: var(--default-border-radius);
    }

    > a {
      color: var(--red);
    }
  }
}

.btnLine {
  padding: 0;
  border: none;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  font-size: var(--fs-sm);
  flex-wrap: wrap;

  > label {
    transition: all 0.3s ease-in-out;
    background-color: var(--secondary-green);
    border: 2px solid var(--secondary-green);
    border-radius: 100px;
    padding: 5px;
    width: 100%;
    text-align: center;
    max-width: 120px;
    box-shadow: none;

    &.checked {
      background-color: var(--white);
      color: var(--secondary-green);
    }

    &:hover:not(.checked) {
      cursor: pointer;
      border: 2px solid var(--black);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    > input {
      display: none;
      visibility: hidden;
    }
  }
}

@media (min-width: 768px) {
  #campBuildingFloors {
    flex-direction: row-reverse;
    justify-content: space-between;

    > .colRight,
    > .colLeft {
      width: calc((100% - 100px) / 2);
    }
  }

  .btnLine {
    flex-wrap: nowrap;
  }
}
