.gallery {
  > h2 {
    margin-bottom: var(--default-margin);
  }

  > .btnLine {
    font-size: var(--fs-p);
    flex-wrap: wrap;
    margin-bottom: var(--default-margin);

    > label {
      padding: 10px 20px;
      display: flex;

      align-items: center;
      justify-content: center;
      width: fit-content;
      max-width: 250px;
    }
  }

  > .images {
    > .category {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: var(--default-margin);
      justify-content: flex-start;

      > .image {
        width: calc((100% - var(--default-margin)) / 2);
        height: 200px;

        display: block;
        background: var(--light-green);
        background-position: center;
        background-size: cover;

        border-radius: var(--small-border-radius);
        box-shadow: var(--inner-shadow);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  > #overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 11;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--default-margin);

    > .overlayBackground {
      position: absolute;
      background-color: rgba(70, 70, 70, 0.75);
      backdrop-filter: blur(5px);
      height: 100%;
      width: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    > .imgHolder {
      position: relative;
      width: fit-content;
      height: fit-content;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > img {
        max-width: 100%;
        max-height: 80%;
        border-top-right-radius: var(--default-border-radius);
        border-top-left-radius: var(--default-border-radius);
        object-fit: cover;
      }

      > .imgDescription {
        width: 100%;
        background-color: var(--white);
        margin: 0;
        padding: var(--default-padding);
        border-bottom-right-radius: var(--default-border-radius);
        border-bottom-left-radius: var(--default-border-radius);

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > p {
          margin: 0;
        }
      }

      > .nav {
        position: absolute;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        z-index: 12;
      }
    }

    button {
      background-color: transparent;
      border: none;
      position: relative;
      font-size: var(--fs-h2);
      color: var(--white);
      text-shadow: var(--drop-shadow);

      &:hover {
        cursor: pointer;
      }

      &.btnClose {
        color: var(--red);
        position: absolute;
        right: var(--default-margin);
        top: var(--default-margin);
        z-index: 10;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .gallery {
    > .images > .category {
    }
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
  .gallery > .images > .category > .image {
    width: calc((100% - 3 * var(--default-margin)) / 4);
  }
}
