.langSelector {
  > select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;

    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    // Stack above custom arrow
    z-index: 1;

    // Remove dropdown arrow in IE10 & IE11
    // @link https://www.filamentgroup.com/lab/select-css.html
    &::-ms-expand {
      display: none;
    }

    // Remove focus outline, will add on alternate element
    outline: none;
  }

  > select {
    text-transform: uppercase;
    font-size: var(--fs-sm);
    font-weight: 400;
    cursor: pointer;
    text-align: center;

    padding: 11px 0;
    max-width: 100px;
    border: solid 2px var(--gray);
    border-radius: var(--default-border-radius);

    &:hover {
      font-weight: 500;
    }

    &:focus-visible {
      outline: var(--black) 2px dashed;
      transition: all 0.3s ease-in-out;
    }
  }
}

@media (min-width: 768px) {
  .langSelector > select {
    max-width: 100%;
    border: none;
  }
}
