.pack {
  background-color: var(--true-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;

  filter: drop-shadow(var(--drop-shadow));

  &.selected {
    outline: solid 5px var(--primary-green);
  }

  > .packHeader,
  > .packRibbon,
  > .packBody {
    padding: 15px 20px;
    width: 100%;
  }

  > .packHeader {
    border-radius: 15px 15px 0px 0px;
    text-align: center;
    > h3,
    > h5 {
      margin: 0;
    }

    > h5 {
      margin-bottom: 5px;
    }
  }

  > .packRibbon {
    background-color: var(--primary-green);
    color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;

    > .packPrice {
      font-size: 13px;
    }
  }

  > .packBody {
    > .item {
      > hr,
      > h6,
      > p {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      > p {
        white-space: pre-line;
      }

      > hr {
        max-width: 100%;
      }
    }
  }
}
