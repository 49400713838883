.btn {
  display: flex;
  transition: all 0.3s ease-in-out;
  border-radius: var(--default-border-radius);
  justify-content: center;
  align-items: center;
  box-shadow: var(--drop-shadow);
  padding: 11px 20px;
  border: transparent solid 2px;
  width: fit-content;

  &:hover {
    box-shadow: var(--drop-shadow-hover);
    transform: translateY(-2px);
  }

  &:focus-visible {
    outline: var(--black) 2px dashed;
    transition: all 0.3s ease-in-out;
  }

  &:active {
    border: var(--black) solid 2px;
    transform: translateY(0px);
    box-shadow: none;
  }

  > i {
    margin-right: 8px;
  }
}

.btn--sm {
  height: 42px !important;
}

.btn--primary {
  background: var(--primary-green);

  &:hover {
    transition: all 0.3s ease-in-out;
  }
}

.btn--secondary {
  background: var(--secondary-green);
  color: var(--black);
}

.btn--white {
  background: var(--white);
  color: var(--black);
}

.btn--100 {
  width: 100%;
}

.btn--img {
  gap: 8px;
  > img {
    height: 24px;
    width: auto;
  }
}

@media (min-width: 768px) {
  .btn:not(.btn--menu) {
    max-width: 300px;
    min-width: 180px;
    margin: auto;
  }
}
