body {
  background-color: var(--white);
}

html,
body {
  // scroll-behavior: smooth;
}

main {
  margin-top: var(--navbar-height);
}

section:not(.hero) {
  margin: 100px auto;
}

hr {
  background: var(--primary-gradient);
  display: block;
  height: 2px;
  border-radius: 100px;
  border: none;
  width: 100%;
  max-width: 80vw;
}

.bg--lightGreen {
  background-color: var(--light-green);
}

.container {
  &.tight {
    max-width: 600px;
  }

  &.medTight {
    max-width: 800px;
  }
}
