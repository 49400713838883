#TeamDetails {
  .teamMember {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;

    > .innerShadow {
      width: 150px;
      height: 150px;
      box-shadow: var(--inner-shadow);
      border-radius: 100%;
      margin-bottom: var(--default-margin);
      background-size: cover;
      background-position: center;
    }

    > h3 {
      margin-bottom: 5px;
    }

    > p {
      margin: 0;
      max-width: 200px;
      color: var(--light-green);
    }

    > label {
      transition: all 0.3s ease-in-out;
      margin-top: var(--default-padding);
      font-size: var(--fs-h4);
      border: solid 2px var(--white);
      background-color: var(--dark-green);
      border-radius: 100px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.checked {
        transition: all 0.3s ease-in-out;
        transform: rotate(90deg);
        background-color: var(--white);
        color: var(--secondary-green);
      }

      &:hover:not(.checked) {
        transition: all 0.3s ease-in-out;
        transform: rotate(10deg);
        color: var(--secondary-green);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;
      }
      > input {
        display: none;
        visibility: hidden;
      }
    }
  }

  > h4 {
    margin-top: 50px;
    margin-bottom: var(--default-margin);
    color: var(--secondary-green);
    text-align: center;
    text-shadow: var(--drop-shadow);
  }

  > .desc {
    max-width: 800px;
    margin: auto;
    white-space: break-spaces;
    color: var(--light-green);
    position: relative;

    > p {
      transition: opacity 0.5s ease-in-out;
      opacity: 1;
      visibility: visible;
      position: relative;
      text-shadow: var(--drop-shadow);

      &.hide-away {
        position: absolute;
        transition: opacity 0.5s ease-in-out;
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  > .btnLine > label {
    background-color: var(--dark-green);
    border: 2px solid var(--dark-green);

    &.checked {
      background-color: var(--white);
      color: var(--dark-green);
    }
  }
}
