fieldset.input {
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.input {
  // Normalizing
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;

  &.half-col {
    width: calc((100% - var(--default-margin)) / 2);
  }

  &.third-col {
    width: calc((100% - 2 * var(--default-margin)) / 3);
  }

  &.auto-col {
    width: auto;
  }

  &.px100-col {
    width: 100px;
  }

  &.rpx100-col {
    width: calc(100% - var(--default-margin) - 100px);
  }

  > small {
    font-size: var(--fs-xs);
    color: var(--gray);
  }

  > label {
    color: var(--black);
    font-size: var(--fs-sm);
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 2px;

    > input,
    > select,
    > textarea {
      transition: all 0.3 ease-in-out;
      background-color: var(--white);
      border-radius: var(--small-border-radius);
      padding: 8px 10px;
      box-shadow: var(--inner-shadow);
      margin: 0 -2px;
      line-height: 24px;
      font-size: var(--fs-p);
      border: none;

      &:focus {
        outline: 2px solid var(--secondary-green) !important;
      }
    }
  }

  > .errorMsg {
    font-size: var(--fs-xs);
    font-weight: 300;
    font-style: italic;
    &--info {
      color: var(--gray);
    }

    &--error {
      font-weight: 500;
      color: var(--red);
    }

    &--success {
      font-weight: 500;
      color: var(--primary-green);
    }
  }

  // Input's specifics
  &__text {
    > label {
      display: flex;
      flex-direction: column-reverse;

      > input:required + span::after {
        content: '*';
        color: var(--red);
      }
    }
  }

  &__checkbox {
    display: flex;
    flex-direction: row;
    gap: 2px;
    position: relative;
    height: 25px;
    align-items: center;

    &--disabled {
      > label:hover {
        cursor: not-allowed !important;

        input:disabled {
          ~ .checkmark {
            cursor: not-allowed;
            background-color: var(--light-gray);
            transition: background-color 0.1s ease-in-out;
          }
        }
      }

      input:disabled {
        ~ .checkmark {
          background-color: var(--light-gray);
          transition: background-color 0.1s ease-in-out;
        }
      }
    }

    > label {
      &:hover {
        cursor: pointer;
      }
      padding-left: 35px;
      input:hover {
        cursor: pointer;

        ~ .checkmark {
          background-color: #e9e9e9;
          transition: background-color 0.1s ease-in-out;
        }
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        &:disabled ~ .checkmark {
          background-color: var(--light-gray);
        }
        &:hover ~ .checkmark {
          background-color: var(--dark-green);
          transition: background-color 0.1s ease-in-out;
        }
        ~ .checkmark {
          background-color: var(--primary-green);
          transition: background-color 0.1s ease-in-out;

          &::after {
            display: block;
          }
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: var(--white);
      border-radius: var(--small-border-radius);
      box-shadow: var(--inner-shadow);
      transition: background-color 0.1s ease-in-out;

      &::after {
        content: '';
        position: absolute;
        display: none;
      }

      &::after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid var(--white);
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  &__number {
    &.persInfos {
      > label {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 2px;

        > input {
          max-width: 100px;
        }
      }
    }

    > label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      font-weight: 500;

      > input {
        width: fit-content;
        max-width: 60px;
      }
    }

    &--rtl {
      > label {
        flex-direction: row-reverse;
      }
    }

    &--multiple {
      display: flex;
      flex-direction: column;

      gap: 10px;

      > fieldset {
        display: flex;
        flex-direction: column;
        border: none;
        padding: 0;
        gap: 10px;

        > .input {
          width: fit-content;
        }

        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          width: calc((100% - 2 * 20px) / 3);

          font-weight: 500;
          font-size: var(--fs-sm);
          letter-spacing: 0.01em;
          width: 100%;

          > input {
            width: 60px;
          }
        }
      }

      > h6 {
        font-size: var(--fs-sm);
        margin: 0;
      }
    }
  }

  &__select {
    &.colSelect > label {
      flex-direction: inherit;
      gap: inherit;
      align-items: inherit;
      justify-content: inherit;
    }

    > label {
      flex-direction: row;
      gap: var(--default-margin);
      align-items: center;
      justify-content: space-between;
    }

    select {
      display: block;
      width: auto;
      max-width: 300px;
      box-sizing: border-box;
      margin: 0;
      box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
      border-radius: 0.5em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%238bb73e%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 0.65em auto, 100%;

      // Remove dropdown arrow in IE10 & IE11
      // @link https://www.filamentgroup.com/lab/select-css.html
      &::-ms-expand {
        display: none;
      }

      // Remove focus outline, will add on alternate element
      outline: none;
    }

    select {
      cursor: pointer;

      &:focus {
        outline: 2px solid var(--secondary-green) !important;
      }
    }
  }

  &__radio {
    gap: var(--default-padding);

    > legend {
      width: 100%;
      font-weight: 700;
      margin-bottom: 10px;
    }

    > label {
      font-weight: 500;
      background-color: var(--white);
      padding: 8px 10px;
      border-radius: var(--small-border-radius);
      box-shadow: var(--drop-shadow-small);
      outline: solid 2px var(--light-green);

      &:hover {
        cursor: pointer;
      }

      &.checked {
        background-color: var(--secondary-green);
        outline: solid 2px var(--primary-green);
      }

      &.disabled {
        background-color: var(--light-gray);
        outline: solid 2px var(--gray);
        color: var(--gray);

        &:hover {
          cursor: not-allowed;
        }
      }

      > input {
        display: none;
        visibility: hidden;
      }
    }

    &--boolean {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      > label {
        height: fit-content;
      }

      > legend {
        width: calc(100% - 50px);
        margin: 0;
      }
    }
  }
}

.checkboxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: var(--white);
  padding: 50px !important;
  gap: var(--default-padding);
  border-radius: var(--default-border-radius);

  > legend {
    font-weight: 700;
    width: 100%;
    margin-top: var(--default-margin);
  }

  label {
    display: flex;
    flex-direction: row;
    gap: 10px;

    > input {
    }
  }

  > small {
    // font-style: italic;
    // font-size: var(--fs-xs);
  }
}
