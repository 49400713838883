.progressBar {
  background-color: var(--white);
  border-radius: 100px;

  &__background {
    background: var(--primary-gradient);
    height: 6px;
    border-radius: 100px;
  }
}
