@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.status {
  display: flex;
  align-items: center;

  &__success {
  }

  &__sending {
    margin: 0px;
    width: auto;

    > i {
      animation: rotate 1.5s linear infinite;
    }
  }

  &__error {
    display: block;

    > i {
      color: var(--red);
    }
  }
}
