* {
  --true-white: #fff;
  --white: #fafafa;
  --light-green: #dae2cb;
  --secondary-green: #8bb73e;
  --primary-green: #467f2b;
  --dark-green: #173316;
  --black: #181818;
  --brown: #4d3317;
  --red: #dd5449;
  --gray: #888888;
  --light-gray: #b6b6b6;

  --info: #007bff;
  --warning: #ff9f33;

  --primary-gradient: linear-gradient(135.23deg, #8bb73e 15.1%, #467f2b 80.41%);
  --dark-gradient: linear-gradient(135.23deg, #173316 15.1%, #467f2b 80.41%);
  --primary-gradient-reverse: linear-gradient(135.23deg, #467f2b 15.1%, #8bb73e 80.41%);

  --drop-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --drop-shadow-small: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --drop-shadow-hover: 0px 4px 6px rgba(0, 0, 0, 0.35);
  --inner-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);

  --fs-h1: 60px;
  --fs-h2: 36px;
  --fs-h3: 25px;
  --fs-h4: 20px;
  --fs-h5: 18px;
  --fs-h6: 16px;
  --fs-p: 16px;
  --fs-sm: 15px;
  --fs-xs: 12px;
  --fs-btn: 17px;

  --default-margin: 20px;
  --default-padding: 15px;
  --default-border-radius: 15px;
  --small-border-radius: 8px;
  --navbar-height: 80px;
  --default-block-width: 800px;
}

#phone::before {
  content: '+41 79 310 14 89';
  display: inline;
}

#mail::before {
  content: 'contact@les-eterpaz.ch';
  display: inline;
}

@media screen and (min-width: 768px) {
  * {
    --fs-h1: 80px;
    --fs-h2: 48px;
    --fs-h3: 28px;
    --fs-h4: 24px;
    --fs-h5: 18px;
    --fs-h6: 17px;
    --fs-p: 17px;
    --fs-sm: 15px;
    --fs-xs: 12px;
    --fs-btn: 17px;

    --default-margin: 20px;
    --default-padding: 15px;
    --navbar-height: 80px;
  }
}
