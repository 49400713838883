// Titles
@import url('https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Subtitle
@import url('https://fonts.googleapis.com/css2?family=Mansalva&display=swap');

// Body
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', Geneva, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: var(--fs-p);
  color: var(--black);
}

p {
  line-height: 1.4em;
}

small {
  font-size: var(--fs-sm);
  font-weight: 400;

  &.xs {
    font-size: var(--fs-xs);
    font-style: italic;
  }
}

.xsmall {
  font-size: var(--fs-xs);
  font-style: italic;
  line-height: 100%;
  font-weight: 300;
}

h1,
h2,
h3,
h5 {
  font-family: 'Grandstander', 'Poppins', sans-serif;
}

h1 {
  font-weight: 900;
  font-size: var(--fs-h1);
  text-transform: uppercase;
  line-height: 0.88em;
  margin: 0;
  margin-bottom: 50px;
}

h2 {
  font-weight: 900;
  font-size: var(--fs-h2);
  line-height: 1em;
  letter-spacing: 0.03em;
  margin: 0;
  margin-bottom: 50px;
}

h3 {
  font-weight: 700;
  font-size: var(--fs-h3);
  line-height: 1em;
  margin: 0;
  margin-bottom: 20px;
}

h4 {
  font-family: 'Mansalva', cursive;
  font-weight: 400;
  font-size: var(--fs-h4);
  margin: 0;
}

h5 {
  font-weight: 800;
  font-size: var(--fs-h5);
  line-height: 1.5em;
  text-align: center;
}

h6 {
  font-weight: 700;
  font-size: var(--fs-h6);
  letter-spacing: 0.02em;
  margin: 0;
  margin-bottom: 1.2rem;
}

.btn {
  text-decoration: none;
  font-size: var(--fs-btn);
  font-weight: 500;
  line-height: 24px;
  color: var(--white);
}

.iconLabel {
  font-size: var(--fs-xs);
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 11px;
  text-align: center;
}

.primaryColor {
  color: var(--primary-blue) !important;
}

a {
  text-decoration: underline;
  color: var(--primary-green);
  transition: all ease-in-out 0.2s;
}

a {
  &:hover {
    color: var(--dark-green);
    transition: all ease-in-out 0.2s;
  }

  &:focus-visible {
    outline: var(--black) 2px dashed;
    border-radius: var(--small-border-radius);
    transition: all 0.3s ease-in-out;
  }
}

.sectionTitle {
  > h2 {
    margin: 0;
  }

  > h4 {
    color: var(--red);
  }
}

.underlinedTitle {
  width: fit-content;
  padding-bottom: 5px;
  border-bottom: dashed var(--secondary-green) 4px;
  margin-bottom: 0;
}

.centeredDashed {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  & + p,
  & + small {
    text-align: center;
  }

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: dashed var(--secondary-green) 4px;
    max-width: 75px;
  }

  &:not(:empty)::before {
    margin-right: var(--default-margin);
  }

  &:not(:empty)::after {
    margin-left: var(--default-margin);
  }
}

.emphase {
  font-weight: bolder;
  &--danger {
    color: #8c0000;
  }

  &--success {
    color: var(--dark-green);
  }
}
