#inProgress {
  &.container {
    margin-bottom: 0;
    padding-bottom: 100px;

    > p {
      text-align: justify;
    }

    > h2 {
      text-align: center;
    }

    > h3 {
      margin-top: 50px;
    }
  }

  .pictograms {
    > .pictoBox {
      background-color: var(--white);
      text-decoration: none;
      border: solid 3px var(--light-green);
      max-width: none;
      > p {
        text-align: center !important;
      }

      > img {
        width: 80%;
      }

      &:hover {
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        border: solid 3px var(--primary-green);
      }
    }
  }
}

#work-in-progress {
  text-align: center;
  .hero {
    background-image: url('../assets/img/work-in-progress-bg.jpg');
    background-position: center;
    background-size: cover;
    background-color: var(--primary-green);
    backdrop-filter: saturate(0);
    margin: 0;
    height: 60vh;
    width: 100%;

    > .hero-overlay {
      width: 100%;
      height: 100%;
      background-color: green;
      background: var(--primary-gradient);
      opacity: 0.5;
      position: absolute;
      top: 0;
      z-index: -1;
    }

    > .container {
      z-index: 1;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      justify-content: center;
      text-align: center;
      padding-bottom: 75px;

      > img {
        max-height: 40vh;
        width: auto;
        object-fit: contain;
      }

      > h1 {
        margin: 0;
        color: var(--white);
      }

      > h4 {
        margin: 0;
        color: var(--white);
      }
    }
  }

  > .container {
    i {
      margin-right: 10px;
    }
  }
}

#inProgressAlert {
  position: fixed;
  top: auto;
  bottom: 0;
  z-index: 20;
  width: 100%;
  background-color: #ff9f33;
  text-align: center;
  padding: 5px var(--default-margin);
  animation: blinker 1s ease-in-out infinite;
  font-weight: 700;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);

  @keyframes blinker {
    50% {
      background-color: #ff8f33;
    }
  }
}
