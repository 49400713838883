#home {
  &Hero > .hero__content {
    padding-bottom: 100px; // Panorama's height

    > img {
      max-height: 60%;
      margin: var(--default-margin) var(--default-margin) 10px;
      filter: drop-shadow(0px 4.18529px 4.18529px rgba(0, 0, 0, 0.25));
    }

    > h1 {
      font-family: 'Mansalva', cursive;
      font-weight: 400;
      font-size: var(--fs-h4);
      margin: 0;
      text-transform: none;
      color: var(--light-green);
      margin: 0 var(--default-margin);
      text-align: center;
    }
  }

  &__summary {
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 720px;
    align-items: center;
    text-align: center;

    .videoHolder {
      width: 100%;
      display: flex;
      justify-content: center;

      > .ytVideo {
        max-width: 550px;
      }
    }

    h2,
    p {
      margin: 0;
    }

    h4 {
      color: var(--red);
      margin-bottom: 5px;
    }

    .imgCol {
      display: block;
      width: 100%;
      max-width: 300px;
      height: 420px;
      border-radius: var(--default-border-radius);
      box-shadow: var(--inner-shadow);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__activitiesCTA {
    background: var(--red);
    background-image: url('../assets/img/home_activities.webp');
    background-size: cover;
    background-position: center;
    box-shadow: var(--inner-shadow);
    margin-top: 0;
    margin-bottom: 0;

    > .overlay {
      position: absolute;

      height: 100%;
    }

    > .CtaContent {
      background: rgba(70, 127, 43, 0.6);
      width: 100%;
      padding: 100px 20px;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: var(--default-margin);
      color: var(--white);

      h2,
      p {
        text-shadow: var(--drop-shadow);
        margin: 0 auto;
      }
    }
  }

  &__ourCamps {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;

    > .camps {
      display: flex;
      flex-direction: column;
      gap: var(--default-margin);

      > .camp {
        display: flex;
        flex-direction: column;
        width: 100%;

        border-radius: var(--default-border-radius);
        background-color: var(--secondary-green);
        box-shadow: var(--drop-shadow);

        > .card-top {
          height: 200px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-top-left-radius: var(--default-border-radius);
          border-top-right-radius: var(--default-border-radius);
        }

        > .card-bottom {
          display: flex;
          flex-direction: column;
          gap: var(--default-padding);
          padding: var(--default-padding);
          white-space: pre-line;

          h3,
          p {
            margin: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  #homeHero > .hero__content {
    > img {
      max-height: 100%;
      max-width: 900px;
      height: 100%;
    }
  }

  #home__summary {
    > .btn {
      margin: 0 auto;
    }
  }

  #home__ourCamps > .camps {
    flex-direction: row;

    > .camp {
      width: calc((100% - 2 * var(--default-margin)) / 3);
    }
  }
}

@media (min-width: 992px) {
  #home__summary {
    > .btn {
      margin: 0;
    }
  }
}

.videoBackground {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

@media (min-width: 768px) {
  .videoBackground {
    display: block;
  }
}
