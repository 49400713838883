#activities {
  &OnSite {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 50px;
    margin-bottom: 0;
    background-image: url(../assets/img/activities_banner.webp);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    width: 100%;

    > .fadeToBottomWhite {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(250, 250, 250, 1) 100%);
    }

    > .fadeToBottomGreen {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(218, 226, 203, 1) 100%);
    }

    > .fadeToTopWhite {
      height: 100px;
      width: 100%;
      margin-top: -100px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(250, 250, 250, 1) 100%);
      // background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 0, 0) 100%);
    }

    h2,
    h4 {
      margin: 0;
      text-align: center;
    }

    h4 {
      color: var(--red);
    }

    .onSiteActivities,
    .onSiteMaterial {
      z-index: 1;
      position: relative;
    }

    .onSiteActivities {
      margin: 50px auto;
      display: flex;
      flex-direction: column;
      gap: var(--default-margin);

      > .onSiteActivity {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        > .icon {
          font-size: 24px;
          width: 50px;
          text-align: center;
        }

        > .texts {
          display: flex;
          flex-direction: column;
          margin-left: var(--default-margin);

          > h6,
          p {
            margin: 0;
          }
        }
      }
    }

    .onSiteMaterial > .row {
      flex-direction: column;
      gap: var(--default-margin);

      h6 {
        color: var(--gray);
        margin-bottom: 5px;
      }

      ul {
        margin: 0;
        list-style-type: circle;
      }
    }
  }

  &ForSchools {
    background-color: var(--light-green);
    margin: 0;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: var(--default-margin);

    > a {
      margin: 0 var(--default-margin);

      @media (min-width: 768px) {
        margin: 0 auto;
      }
    }

    .sectionTitles {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 768px) {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    > .container {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }

  &Mtb {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 100px;
    background-color: var(--light-green);

    .row {
      flex-direction: column-reverse;
      justify-content: flex-start;
      gap: var(--default-margin);

      .textCol {
        margin-bottom: 50px;
        white-space: break-spaces;
        text-align: left;

        @media (min-width: 768px) {
          text-align: center;
        }

        h2 {
          margin: 0;
        }

        .btn {
          margin: var(--default-margin) 0px;

          @media (min-width: 768px) {
            margin: var(--default-margin) auto;
          }
        }
      }

      .imgCol {
        width: fit-content;

        img {
          height: 100%;
          width: 100%;
          max-height: 300px;
          border-radius: var(--default-border-radius);
          object-fit: cover;
        }
      }
    }
  }

  &Animations > .dashedBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    gap: 50px;

    border: 4px dashed var(--secondary-green);
    border-radius: var(--default-border-radius);

    > .sectionTitle {
      text-align: left;
      @media (min-width: 678px) {
        text-align: center;
      }

      > h4 {
        margin-bottom: 5px;
      }

      > p {
        white-space: pre-line;
      }
    }

    .activity {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 20px;

      &Desc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: var(--default-padding);

        h3,
        p {
          margin: 0;
        }

        p {
          white-space: pre-line;
        }

        > img {
          height: 36px;
        }
      }

      &Img {
        height: 310px;
        width: 100%;
        box-shadow: var(--inner-shadow);
        border-radius: var(--default-border-radius);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
  }

  &Map {
  }
}

@media (min-width: 768px) {
  #activitiesOnSite {
    background-position: center;
  }

  #activitiesMtb .row {
    align-items: center;

    > .textCol {
      margin-bottom: 0px;

      > a {
        margin: 0px;
      }
    }

    > .imgCol {
      display: flex;
      width: 100%;
    }
  }

  #activitiesAnimations > .dashedBox {
    padding-left: var(--default-margin);
    padding-right: var(--default-margin);

    .activity {
      gap: 50px;

      &Img {
        width: 100%;
        max-width: 340px;
      }
    }
  }

  // Lignes impaires
  #activitiesAnimations .activity:nth-of-type(2n-1) {
    flex-direction: row;
  }

  // Ligne paires
  #activitiesAnimations .activity:nth-of-type(2n) {
    flex-direction: row-reverse;
  }

  #activitiesOnSite {
    .onSiteMaterial > .row {
      flex-direction: row;
    }

    .onSiteActivities {
      flex-direction: row;
      flex-wrap: wrap;

      > .onSiteActivity {
        width: calc(50% - var(--default-margin));
      }
    }
  }
}

@media (min-width: 992px) {
  #activitiesAnimations > .dashedBox {
    padding-left: 50px;
    padding-right: 50px;
  }

  #activitiesMtb {
    .container {
      max-width: 100%;
    }

    .row {
      flex-direction: column-reverse;
      align-items: center;

      > .imgCol {
        margin-bottom: 0;
        width: fit-content;
      }
    }
  }
}

@media (min-width: 1200px) {
  #activitiesMtb {
    .container {
      max-width: 1140px;
    }
    .row {
      > .imgCol {
      }
    }
  }
}
