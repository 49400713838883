#map {
  height: 350px;
  margin-bottom: 100px;
  border-radius: var(--default-border-radius);
  box-shadow: var(--inner-shadow);
  z-index: 1;

  .leaflet-popup-content {
    white-space: pre-line;
  }
}
