@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

details {
  summary {
    background-color: var(--secondary-green);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--default-padding) 20px;
    border-radius: var(--default-border-radius);

    &:hover {
      cursor: pointer;
    }

    > .summary-content {
      overflow: hidden;
    }

    > .detail::after {
      color: var(--black);
      content: '+';
      margin-left: 2px;
    }
  }

  &[open] {
    > .summary-content {
      background-color: var(--white);
      animation: fadeIn 0.3s ease-in-out;
      border-bottom-right-radius: var(--default-border-radius);
      border-bottom-left-radius: var(--default-border-radius);
      // box-shadow: var(--inner-shadow);
    }

    > summary {
      border-radius: var(--default-border-radius) var(--default-border-radius) 0px 0px;

      > .detail {
        // transition: color 0.1s ease-in-out;
        color: var(--secondary-green);
        &::after {
          content: '-';
          margin-left: 4px;
        }
      }
    }
  }

  p {
    margin: 0;
    padding: 15px 20px;

    &:not(.underlined) {
      padding: 0 20px;

      &::marker {
        content: '-';
      }
    }

    &:first-of-type {
      padding-top: 15px;
    }

    &:last-of-type {
      padding-bottom: 15px;
    }
  }
}
