.pictograms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--default-margin);
  align-items: center;
  justify-content: center;
  align-items: stretch;

  > .pictoBox {
    width: 100%;
    max-width: 180px;
    background-color: var(--light-green);
    box-shadow: var(--inner-shadow);
    border-radius: var(--default-border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 5px;
    gap: 10px;

    > img {
      height: 50px;
      width: auto;
    }

    > i {
      font-size: 50px;
      background-image: var(--dark-gradient);
      background-clip: text;
      color: transparent;
    }

    > p {
      margin: 0;
      text-align: center;
      white-space: pre-line;
    }
  }
}

@media (min-width: 576px) {
  .pictograms {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

@media (min-width: 768px) {
  .pictograms {
    flex-direction: row;
    justify-content: center;
  }
}
