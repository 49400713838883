#footer {
  width: 100%;
  color: var(--white);
  background-color: var(--brown);
  text-align: center;

  a:not(.btn) {
    color: var(--light-green);

    border-bottom-style: dashed;
    border-bottom-width: 2px;
    border-bottom-color: transparent;
    transition: border-bottom-width 0s;
    transition: border-bottom-color 0.3s;

    &:hover {
      color: var(--white);
      border-bottom-width: 2px;
      border-bottom-color: var(--light-green);
      transition: border-bottom-width 0s;
      transition: border-bottom-color 0.3s;
    }
  }

  > hr {
    background: none;
    border-top: 1px dashed var(--white);
    display: block;
    height: 2px;
    border-radius: 100px;
    max-width: 80vw;
  }

  a:not(.btn) {
    color: var(--white);
    text-decoration: none;
    line-height: 24px;
  }

  &Top {
    padding: 50px 20px 120px 20px;
  }

  &Main {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    p {
      margin: 0;
    }

    > .row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 50px;
      padding-top: 50px;
      margin-bottom: 50px;

      > .footerMainCol {
        width: 100%;
        padding: 0;
      }
    }
  }

  &Bottom {
    color: var(--light-green);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 50px 20px;
  }
}
@media (min-width: 768px) {
  #footerMain > .row {
    flex-direction: row;
    justify-content: center;
    gap: var(--default-margin);
    margin-left: 0;
    margin-right: 0;

    > .footerMainCol {
      width: calc((100% - 3 * var(--default-margin)) / 4);
      max-width: 360px;
      text-align: left;
    }
  }
}

@media (min-width: 992px) {
}
