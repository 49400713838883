#groupsCalendar {
  width: 100%;
  height: 500px;
}

#calendars {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 50px;
  border-radius: var(--default-border-radius);
  padding: var(--default-padding);

  .btnRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > button {
      background: none;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:hover {
        cursor: pointer;
        > i {
          transition: all 0.3s ease-in-out;
          color: var(--white);
          background-color: var(--primary-green);
        }

        > small {
          transition: all 0.3s ease-in-out;
          transform: translateY(2px);
        }
      }

      > i {
        transition: all 0.3s ease-in-out;
        font-size: 24px;
        color: var(--primary-green);
        border: solid 2px var(--primary-green);
        border-radius: 100px;
        display: flex;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
      }

      > small {
        transition: all 0.3s ease-in-out;
        padding-top: 3px;
        font-size: var(--fs-xs);
      }

      &#prevBtn {
      }

      &#nextBtn {
      }
    }
  }
}

.calendar {
  --cal-free: #c8e690;
  --cal-busy: #e7a5a0;
  width: 100%;

  &Header {
    > th {
      padding: 20px 12px;
    }
  }

  &Subheader > td {
    text-align: center;
    font-size: var(--fs-sm);
    color: var(--gray);
    padding-bottom: 12px;
  }

  &Body {
    > tr {
      > td {
        padding: 10px 15px;
        text-align: center;
        border: solid 1px var(--white);

        &.date-free,
        &.date-busy-next,
        &.date-busy-prev {
          background: var(--cal-free);
        }

        &.date-busy {
          background-color: var(--cal-busy);
        }

        &.date-busy-prev {
          // Rouge à gauche
          background: linear-gradient(
            90deg,
            rgba(231, 165, 160, 1) 50%,
            rgba(200, 230, 144, 1) 50%
          );
        }

        &.date-busy-next {
          // Rouge à droite
          background: linear-gradient(
            270deg,
            rgba(231, 165, 160, 1) 50%,
            rgba(200, 230, 144, 1) 50%
          );
        }

        &.date-empty {
          background: none;
        }
      }
    }
  }
}
