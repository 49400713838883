#cga {
  background-color: var(--light-green);
  > .container {
    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 100px;
    }
    > .subsections {
      display: flex;
      flex-direction: column;
      gap: var(--default-margin);

      .spec {
        max-width: var(--default-block-width);

        .summary-content > p {
          margin-bottom: var(--default-padding);

          display: flex;
          flex-direction: column;

          white-space: pre-line;

          > a {
            font-size: var(--fs-sm);
          }
        }
      }
    }
  }

  &.lpd {
    white-space: pre-line;
  }
}
